import { format } from 'date-fns'
import { get } from 'lodash-es'
import { customAlphabet } from 'nanoid'

// factories (NOTE: cannot re-export from  server-side`schema.ts` since migration fails):

export const createId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 12)

export const createUid = (today: Date, prefix: string): string => {
  const suffix = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 3)
  const formattedDate = format(today, 'yyyyMMdd') // e.g. '20240824'
  return `${prefix}-${formattedDate}-${suffix()}`
}

export const createApikey = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 64)

// icons:

export const ICONS: Record<Entity, `i-mdi-${string}`> = {
  accounts: 'i-mdi-office-building',
  activities: 'i-mdi-calendar',
  apikeys: 'i-mdi-key',
  applications: 'i-mdi-apps',
  auditlogs: 'i-mdi-history',
  banks: 'i-mdi-bank',
  branches: 'i-mdi-map-marker',
  campaigns: 'i-mdi-bullhorn',
  categories: 'i-mdi-label-multiple',
  cfdis: 'i-mdi-receipt',
  contacts: 'i-mdi-account-group',
  deals: 'i-mdi-tag',
  industries: 'i-mdi-office-building',
  inquiries: 'i-mdi-message-text',
  issues: 'i-mdi-radiobox-marked',
  financialaccounts: 'i-mdi-cash-register',
  fulfillmentorders: 'i-mdi-package',
  fulfillmentorderitems: 'i-mdi-barcode',
  notes: 'i-mdi-note-text',
  notifications: 'i-mdi-bell',
  pricelists: 'i-mdi-view-list',
  pricelistitems: 'i-mdi-barcode',
  productionorders: 'i-mdi-factory',
  productionorderitems: 'i-mdi-barcode',
  products: 'i-mdi-package-variant-closed',
  productitems: 'i-mdi-barcode',
  fulfillment: 'i-mdi-chart-gantt',
  quotes: 'i-mdi-file-document-edit',
  quoteitems: 'i-mdi-barcode',
  salesorders: 'i-mdi-cart',
  salesorderitems: 'i-mdi-barcode',
  serviceitems: 'i-mdi-barcode',
  services: 'i-mdi-tools',
  tasks: 'i-mdi-checkbox-marked-circle',
  transactions: 'i-mdi-swap-horizontal-circle',
  users: 'i-mdi-account',
  usernotifications: 'i-mdi-bell',
}

// options:

// avoid using Record<> to allow recursive types (see https://stackoverflow.com/a/76583105/23545143)
export type Options = { [k: string]: Option[] | Options }

export type Option = {
  value: string | number
  label: string
  icon?: string
  avatar?: { src?: string, alt: string }
  color?: string
  disabled?: boolean
  className?: string
  [k: string]: unknown // extra properties e.g. description
}

export const optionsShared = {
  authProvider: [
    {
      value: 'email',
      label: 'Email',
      icon: 'i-mdi-email',
    },
    {
      value: 'google',
      label: 'Google',
      icon: 'i-logos-google-icon',
    },
    {
      value: 'github',
      label: 'GitHub',
      icon: 'i-mdi-github',
    },
    {
      value: 'microsoft',
      label: 'Microsoft',
      icon: 'i-logos-microsoft-icon',
    },
    {
      value: 'facebook',
      label: 'Facebook',
      icon: 'i-logos-facebook',
    },
    {
      value: 'linkedin',
      label: 'LinkedIn',
      icon: 'i-logos-linkedin-icon',
    },
    {
      value: 'apple',
      label: 'Apple',
      icon: 'i-mdi-apple',
    },
  ],
  alert: [
    { value: 'info', label: 'Información', icon: 'i-mdi-information', color: 'blue' },
    { value: 'warning', label: 'Advertencia', icon: 'i-mdi-alert', color: 'amber' },
    { value: 'error', label: 'Error', icon: 'i-mdi-alert-circle', color: 'red' },
    { value: 'success', label: 'Éxito', icon: 'i-mdi-check-circle', color: 'green' },
  ],
  // from https://gist.github.com/keeguon/2310008?permalink_comment_id=4970109#gistcomment-4970109
  countryCode: [
    { value: 'MX', label: 'MÉXICO' },
    { value: 'US', label: 'ESTADOS UNIDOS' },
    { value: 'AF', label: 'AFGANISTÁN' },
    { value: 'AX', label: 'ISLAS ÅLAND' },
    { value: 'AL', label: 'ALBANIA' },
    { value: 'DZ', label: 'ARGELIA' },
    { value: 'AS', label: 'SAMOA AMERICANA' },
    { value: 'AD', label: 'ANDORRA' },
    { value: 'AO', label: 'ANGOLA' },
    { value: 'AI', label: 'ANGUILA' },
    { value: 'AQ', label: 'ANTÁRTIDA' },
    { value: 'AG', label: 'ANTIGUA Y BARBUDA' },
    { value: 'AR', label: 'ARGENTINA' },
    { value: 'AM', label: 'ARMENIA' },
    { value: 'AW', label: 'ARUBA' },
    { value: 'AU', label: 'AUSTRALIA' },
    { value: 'AT', label: 'AUSTRIA' },
    { value: 'AZ', label: 'AZERBAIYÁN' },
    { value: 'BS', label: 'BAHAMAS' },
    { value: 'BH', label: 'BARÉIN' },
    { value: 'BD', label: 'BANGLADÉS' },
    { value: 'BB', label: 'BARBADOS' },
    { value: 'BY', label: 'BIELORRUSIA' },
    { value: 'BE', label: 'BÉLGICA' },
    { value: 'BZ', label: 'BELICE' },
    { value: 'BJ', label: 'BENÍN' },
    { value: 'BM', label: 'BERMUDAS' },
    { value: 'BT', label: 'BUTÁN' },
    { value: 'BO', label: 'BOLIVIA' },
    { value: 'BA', label: 'BOSNIA Y HERZEGOVINA' },
    { value: 'BW', label: 'BOTSUANA' },
    { value: 'BV', label: 'ISLA BOUVET' },
    { value: 'BR', label: 'BRASIL' },
    { value: 'IO', label: 'TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO' },
    { value: 'BN', label: 'BRUNÉI' },
    { value: 'BG', label: 'BULGARIA' },
    { value: 'BF', label: 'BURKINA FASO' },
    { value: 'BI', label: 'BURUNDI' },
    { value: 'KH', label: 'CAMBOYA' },
    { value: 'CM', label: 'CAMERÚN' },
    { value: 'CA', label: 'CANADÁ' },
    { value: 'CV', label: 'CABO VERDE' },
    { value: 'KY', label: 'ISLAS CAIMÁN' },
    { value: 'CF', label: 'REPÚBLICA CENTROAFRICANA' },
    { value: 'TD', label: 'CHAD' },
    { value: 'CL', label: 'CHILE' },
    { value: 'CN', label: 'CHINA' },
    { value: 'CX', label: 'ISLA DE NAVIDAD' },
    { value: 'CC', label: 'ISLAS COCOS' },
    { value: 'CO', label: 'COLOMBIA' },
    { value: 'KM', label: 'COMORAS' },
    { value: 'CG', label: 'CONGO' },
    { value: 'CD', label: 'REPÚBLICA DEMOCRÁTICA DEL CONGO' },
    { value: 'CK', label: 'ISLAS COOK' },
    { value: 'CR', label: 'COSTA RICA' },
    { value: 'CI', label: 'COSTA DE MARFIL' },
    { value: 'HR', label: 'CROACIA' },
    { value: 'CU', label: 'CUBA' },
    { value: 'CY', label: 'CHIPRE' },
    { value: 'CZ', label: 'REPÚBLICA CHECA' },
    { value: 'DK', label: 'DINAMARCA' },
    { value: 'DJ', label: 'YIBUTI' },
    { value: 'DM', label: 'DOMINICA' },
    { value: 'DO', label: 'REPÚBLICA DOMINICANA' },
    { value: 'EC', label: 'ECUADOR' },
    { value: 'EG', label: 'EGIPTO' },
    { value: 'SV', label: 'EL SALVADOR' },
    { value: 'GQ', label: 'GUINEA ECUATORIAL' },
    { value: 'ER', label: 'ERITREA' },
    { value: 'EE', label: 'ESTONIA' },
    { value: 'ET', label: 'ETIOPÍA' },
    { value: 'FK', label: 'ISLAS MALVINAS' },
    { value: 'FO', label: 'ISLAS FEROE' },
    { value: 'FJ', label: 'FIYI' },
    { value: 'FI', label: 'FINLANDIA' },
    { value: 'FR', label: 'FRANCIA' },
    { value: 'GF', label: 'GUAYANA FRANCESA' },
    { value: 'PF', label: 'POLINESIA FRANCESA' },
    { value: 'TF', label: 'TERRITORIOS AUSTRALES FRANCESES' },
    { value: 'GA', label: 'GABÓN' },
    { value: 'GM', label: 'GAMBIA' },
    { value: 'GE', label: 'GEORGIA' },
    { value: 'DE', label: 'ALEMANIA' },
    { value: 'GH', label: 'GHANA' },
    { value: 'GI', label: 'GIBRALTAR' },
    { value: 'GR', label: 'GRECIA' },
    { value: 'GL', label: 'GROENLANDIA' },
    { value: 'GD', label: 'GRANADA' },
    { value: 'GP', label: 'GUADALUPE' },
    { value: 'GU', label: 'GUAM' },
    { value: 'GT', label: 'GUATEMALA' },
    { value: 'GG', label: 'GUERNSEY' },
    { value: 'GN', label: 'GUINEA' },
    { value: 'GW', label: 'GUINEA-BISSAU' },
    { value: 'GY', label: 'GUYANA' },
    { value: 'HT', label: 'HAITÍ' },
    { value: 'HM', label: 'ISLAS HEARD Y MCDONALD' },
    { value: 'VA', label: 'SANTA SEDE (CIUDAD DEL VATICANO)' },
    { value: 'HN', label: 'HONDURAS' },
    { value: 'HK', label: 'HONG KONG' },
    { value: 'HU', label: 'HUNGRÍA' },
    { value: 'IS', label: 'ISLANDIA' },
    { value: 'IN', label: 'INDIA' },
    { value: 'ID', label: 'INDONESIA' },
    { value: 'IR', label: 'IRÁN' },
    { value: 'IQ', label: 'IRAK' },
    { value: 'IE', label: 'IRLANDA' },
    { value: 'IM', label: 'ISLA DE MAN' },
    { value: 'IL', label: 'ISRAEL' },
    { value: 'IT', label: 'ITALIA' },
    { value: 'JM', label: 'JAMAICA' },
    { value: 'JP', label: 'JAPÓN' },
    { value: 'JE', label: 'JERSEY' },
    { value: 'JO', label: 'JORDANIA' },
    { value: 'KZ', label: 'KAZAJISTÁN' },
    { value: 'KE', label: 'KENIA' },
    { value: 'KI', label: 'KIRIBATI' },
    { value: 'KP', label: 'COREA DEL NORTE' },
    { value: 'KR', label: 'COREA DEL SUR' },
    { value: 'KW', label: 'KUWAIT' },
    { value: 'KG', label: 'KIRGUISTÁN' },
    { value: 'LA', label: 'LAOS' },
    { value: 'LV', label: 'LETONIA' },
    { value: 'LB', label: 'LÍBANO' },
    { value: 'LS', label: 'LESOTO' },
    { value: 'LR', label: 'LIBERIA' },
    { value: 'LY', label: 'LIBIA' },
    { value: 'LI', label: 'LIECHTENSTEIN' },
    { value: 'LT', label: 'LITUANIA' },
    { value: 'LU', label: 'LUXEMBURGO' },
    { value: 'MO', label: 'MACAO' },
    { value: 'MK', label: 'MACEDONIA' },
    { value: 'MG', label: 'MADAGASCAR' },
    { value: 'MW', label: 'MALAUI' },
    { value: 'MY', label: 'MALASIA' },
    { value: 'MV', label: 'MALDIVAS' },
    { value: 'ML', label: 'MALÍ' },
    { value: 'MT', label: 'MALTA' },
    { value: 'MH', label: 'ISLAS MARSHALL' },
    { value: 'MQ', label: 'MARTINICA' },
    { value: 'MR', label: 'MAURITANIA' },
    { value: 'MU', label: 'MAURICIO' },
    { value: 'YT', label: 'MAYOTTE' },
    { value: 'FM', label: 'MICRONESIA' },
    { value: 'MD', label: 'MOLDAVIA' },
    { value: 'MC', label: 'MÓNACO' },
    { value: 'MN', label: 'MONGOLIA' },
    { value: 'MS', label: 'MONTSERRAT' },
    { value: 'MA', label: 'MARRUECOS' },
    { value: 'MZ', label: 'MOZAMBIQUE' },
    { value: 'MM', label: 'BIRMANIA' },
    { value: 'NA', label: 'NAMIBIA' },
    { value: 'NR', label: 'NAURU' },
    { value: 'NP', label: 'NEPAL' },
    { value: 'NL', label: 'PAÍSES BAJOS' },
    { value: 'AN', label: 'ANTILLAS NEERLANDESAS' },
    { value: 'NC', label: 'NUEVA CALEDONIA' },
    { value: 'NZ', label: 'NUEVA ZELANDA' },
    { value: 'NI', label: 'NICARAGUA' },
    { value: 'NE', label: 'NÍGER' },
    { value: 'NG', label: 'NIGERIA' },
    { value: 'NU', label: 'NIUE' },
    { value: 'NF', label: 'ISLA NORFOLK' },
    { value: 'MP', label: 'ISLAS MARIANAS DEL NORTE' },
    { value: 'NO', label: 'NORUEGA' },
    { value: 'OM', label: 'OMÁN' },
    { value: 'PK', label: 'PAKISTÁN' },
    { value: 'PW', label: 'PALAOS' },
    { value: 'PS', label: 'PALESTINA' },
    { value: 'PA', label: 'PANAMÁ' },
    { value: 'PG', label: 'PAPÚA NUEVA GUINEA' },
    { value: 'PY', label: 'PARAGUAY' },
    { value: 'PE', label: 'PERÚ' },
    { value: 'PH', label: 'FILIPINAS' },
    { value: 'PN', label: 'PITCAIRN' },
    { value: 'PL', label: 'POLONIA' },
    { value: 'PT', label: 'PORTUGAL' },
    { value: 'PR', label: 'PUERTO RICO' },
    { value: 'QA', label: 'CATAR' },
    { value: 'RE', label: 'REUNIÓN' },
    { value: 'RO', label: 'RUMANIA' },
    { value: 'RU', label: 'RUSIA' },
    { value: 'RW', label: 'RUANDA' },
    { value: 'SH', label: 'SANTA HELENA' },
    { value: 'KN', label: 'SAN CRISTÓBAL Y NIEVES' },
    { value: 'LC', label: 'SANTA LUCÍA' },
    { value: 'PM', label: 'SAN PEDRO Y MIQUELÓN' },
    { value: 'VC', label: 'SAN VICENTE Y LAS GRANADINAS' },
    { value: 'WS', label: 'SAMOA' },
    { value: 'SM', label: 'SAN MARINO' },
    { value: 'ST', label: 'SANTO TOMÉ Y PRÍNCIPE' },
    { value: 'SA', label: 'ARABIA SAUDITA' },
    { value: 'SN', label: 'SENEGAL' },
    { value: 'CS', label: 'SERBIA Y MONTENEGRO' },
    { value: 'SC', label: 'SEYCHELLES' },
    { value: 'SL', label: 'SIERRA LEONA' },
    { value: 'SG', label: 'SINGAPUR' },
    { value: 'SK', label: 'ESLOVAQUIA' },
    { value: 'SI', label: 'ESLOVENIA' },
    { value: 'SB', label: 'ISLAS SALOMÓN' },
    { value: 'SO', label: 'SOMALIA' },
    { value: 'ZA', label: 'SUDÁFRICA' },
    { value: 'GS', label: 'GEORGIA DEL SUR Y LAS ISLAS SANDWICH DEL SUR' },
    { value: 'ES', label: 'ESPAÑA' },
    { value: 'LK', label: 'SRI LANKA' },
    { value: 'SD', label: 'SUDÁN' },
    { value: 'SR', label: 'SURINAM' },
    { value: 'SJ', label: 'SVALBARD Y JAN MAYEN' },
    { value: 'SZ', label: 'SUAZILANDIA' },
    { value: 'SE', label: 'SUECIA' },
    { value: 'CH', label: 'SUIZA' },
    { value: 'SY', label: 'SIRIA' },
    { value: 'TW', label: 'TAIWÁN' },
    { value: 'TJ', label: 'TAYIKISTÁN' },
    { value: 'TZ', label: 'TANZANIA' },
    { value: 'TH', label: 'TAILANDIA' },
    { value: 'TL', label: 'TIMOR ORIENTAL' },
    { value: 'TG', label: 'TOGO' },
    { value: 'TK', label: 'TOKELAU' },
    { value: 'TO', label: 'TONGA' },
    { value: 'TT', label: 'TRINIDAD Y TOBAGO' },
    { value: 'TN', label: 'TÚNEZ' },
    { value: 'TR', label: 'TURQUÍA' },
    { value: 'TM', label: 'TURKMENISTÁN' },
    { value: 'TC', label: 'ISLAS TURCAS Y CAICOS' },
    { value: 'TV', label: 'TUVALU' },
    { value: 'UG', label: 'UGANDA' },
    { value: 'UA', label: 'UCRANIA' },
    { value: 'AE', label: 'EMIRATOS ÁRABES UNIDOS' },
    { value: 'GB', label: 'REINO UNIDO' },
    { value: 'UM', label: 'ISLAS ULTRAMARINAS MENORES DE ESTADOS UNIDOS' },
    { value: 'UY', label: 'URUGUAY' },
    { value: 'UZ', label: 'UZBEKISTÁN' },
    { value: 'VU', label: 'VANUATU' },
    { value: 'VE', label: 'VENEZUELA' },
    { value: 'VN', label: 'VIETNAM' },
    { value: 'VG', label: 'ISLAS VÍRGENES BRITÁNICAS' },
    { value: 'VI', label: 'ISLAS VÍRGENES DE LOS ESTADOS UNIDOS' },
    { value: 'WF', label: 'WALLIS Y FUTUNA' },
    { value: 'EH', label: 'SÁHARA OCCIDENTAL' },
    { value: 'YE', label: 'YEMEN' },
    { value: 'ZM', label: 'ZAMBIA' },
    { value: 'ZW', label: 'ZIMBABUE' },
  ],
  i18n: [
    { value: 'es-ES', label: 'Español' },
    { value: 'en-GB', label: 'English' },
  ],
  incoterm: [
    { value: 'pending', label: 'Pendiente de definir', description: 'El Cliente aún no ha definido el término de entrega.' },
    { value: 'EXW', label: 'En Fábrica (EXW)', description: 'Nosotros hacemos disponible la mercancía, y el Cliente asume los costos y riesgos desde la recogida.' },
    { value: 'FCA', label: 'Libre Transportista (FCA)', description: 'Nosotros entregamos la mercancía al transportista en un lugar designado, luego el riesgo es del Cliente.' },
    { value: 'CPT', label: 'Transporte Pagado Hasta (CPT)', description: 'Nosotros pagamos el transporte hasta un destino acordado, el riesgo es del Cliente después de la entrega.' },
    { value: 'CIP', label: 'Transporte y Seguro Pagado Hasta (CIP)', description: 'Nosotros pagamos transporte y seguro hasta el destino acordado, el riesgo pasa al Cliente después de la entrega.' },
    { value: 'DAP', label: 'Entregado en Lugar (DAP)', description: 'Nosotros entregamos la mercancía en un lugar acordado, el Cliente asume los riesgos tras la entrega.' },
    { value: 'DPU', label: 'Entregado en Lugar y Descargado (DPU)', description: 'Nosotros entregamos y descargamos en el lugar acordado, el Cliente asume los riesgos después de la descarga.' },
    { value: 'DDP', label: 'Entregado con Derechos Pagados (DDP)', description: 'Nosotros nos encargamos de todo, incluido el despacho aduanal, hasta las instalaciones del Cliente.' },
    { value: 'FAS', label: 'Libre al Costado del Buque (FAS)', description: 'Nosotros entregamos al costado del buque, el Cliente asume riesgos y costos desde allí.' },
    { value: 'FOB', label: 'Libre a Bordo (FOB)', description: 'Nosotros cargamos la mercancía al buque, el Cliente asume los riesgos una vez a bordo.' },
    { value: 'CFR', label: 'Costo y Flete (CFR)', description: 'Nosotros pagamos el flete hasta el puerto de destino, el Cliente asume los riesgos al embarcar.' },
    { value: 'CIF', label: 'Costo, Seguro y Flete (CIF)', description: 'Nosotros pagamos el flete y seguro, pero el Cliente asume los riesgos desde el embarque.' },
  ],
  views: [
    { value: 'table', label: 'Tabla', icon: 'i-mdi-table' },
    { value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' },
    { value: 'gallery', label: 'Galería', icon: 'i-mdi-view-grid' },
    { value: 'inbox', label: 'Vista previa', icon: 'i-mdi-view-split-vertical' },
    { value: 'kanban', label: 'Kanban', icon: 'i-mdi-view-column' },
    { value: 'calendar', label: 'Calendario', icon: 'i-mdi-calendar' },
  ],
  entity: [
    { value: 'accounts', label: 'Cuentas' },
    { value: 'activities', label: 'Actividades' },
    { value: 'applications', label: 'Aplicaciones' },
    { value: 'auditlogs', label: 'Registros de auditoría' },
    { value: 'banks', label: 'Bancos' },
    { value: 'branches', label: 'Sucursales' },
    { value: 'categories', label: 'Categorías' },
    { value: 'campaigns', label: 'Campañas' },
    { value: 'cfdis', label: 'CFDIs' },
    { value: 'contacts', label: 'Contactos' },
    { value: 'deals', label: 'Negocios' },
    { value: 'industries', label: 'Industrias' },
    { value: 'inquiries', label: 'Solicitudes' },
    { value: 'notes', label: 'Notas' },
    { value: 'notifications', label: 'Notificaciones' },
    { value: 'pricelists', label: 'Listas de precios' },
    { value: 'pricelistitems', label: 'Precios' },
    { value: 'products', label: 'Productos' },
    { value: 'productitems', label: 'Items' },
    { value: 'salesorders', label: 'Órdenes de venta' },
    { value: 'quotes', label: 'Cotizaciones' },
    { value: 'services', label: 'Servicios' },
    { value: 'serviceitems', label: 'Items' },
    { value: 'tasks', label: 'Tareas' },
    { value: 'tests', label: 'Pruebas' },
    { value: 'transactions', label: 'Transacciones' },
    { value: 'financialaccounts', label: 'Cuentas financieras' },
    { value: 'units', label: 'Unidades' },
    { value: 'users', label: 'Usuarios' },
    { value: 'productionorders', label: 'Órdenes de trabajo' },
  ],
  currency: [
    { value: 'USD', label: 'USD', title: 'USD - Dólar Americano', description: 'Dólar americano', color: 'green' },
    { value: 'MXN', label: 'MXN', title: 'MXN - Peso Mexicano', description: 'Peso mexicano', color: 'blue' },
  ],
  deletedAt: [
    { label: 'Activos', value: '$isNull', icon: 'i-mdi-inbox' },
    { label: 'Archivados', value: '$isNotNull', icon: 'i-mdi-archive' },
  ],
} as const satisfies Record<string, Option[]>

// transformations:

/** Simple heuristic to convert an object to an Option (usage: ITEMS.map(toOption)) */
export const toOption = ({
  id,
  key,
  name,
  uid,
  title,
  description,
  i18n,
  ...row
}: Record<string, unknown>): Option => {
  const value = (row?.value ?? id ?? key) as string
  let label = (row?.label ?? name ?? title ?? uid) as string
  if (!label) {
    const { es = {}, en = {} } = (i18n ?? {}) as Record<string, Record<string, string>>
    if (es) label = (es.name ?? es.title ?? es.description ?? es.label ?? value) as string
    else if (en) label = (en.name ?? en.title ?? en.description ?? en.label ?? value) as string
  }
  const src = row?.image as string
  const avatar = src ? { src, alt: label } : { alt: label } // else return `alt` to render initials
  const color = row?.color as string
  return { value, label, avatar, color, description }
}

export const toFilteredOptions = (
  data: Record<string, unknown>[] = [],
  itemKey: string,
  valueKey: string,
  labelKey: string,
): Option[] => {
  const allOptions = data
    .map(item => item[itemKey])
    .filter(Boolean)
    .map(item => ({
      value: item[valueKey],
      label: item[labelKey],
      avatar: item.avatar ?? item.image ? { src: item.image as string, alt: item[labelKey] } : { alt: item[labelKey] },
    })) as Option[]

  const seenKeys = new Set()
  const uniqueOptions: Option[] = []

  allOptions.forEach((item) => {
    if (!seenKeys.has(item.value)) {
      seenKeys.add(item.value)
      uniqueOptions.push(item)
    }
  })

  return uniqueOptions
}

// WORKAROUND: omit avatar for performance until @nuxt/ui-pro@v3 lands with fix
// see https://github.com/nuxt/ui/issues/388 and https://github.com/nuxt/ui/issues/280
export const toOptionWithoutAvatar = (row: Record<string, unknown>): Option => {
  const { avatar, ...rest } = toOption(row)
  return rest
}

// avoids calling .find() for each row (O(n^2) -> O(n)) and uses a
// Map instead of a plain object (via .reduce()) for much better performance
export const toMapByKey = <T extends object>(array: T[] = [], key: keyof T): Map<T[keyof T], T> => {
  const map = new Map<T[keyof T], T>()
  for (const item of array) {
    const value = get(item, key)
    if (value) map.set(value, item)
  }
  return map
}

export const toMapByGroupKey = <T extends object, K extends keyof T>(array: T[] = [], key: K): Map<T[K], T[]> => {
  const map = new Map<T[K], T[]>()

  for (const item of array) {
    const value = item[key]

    if (value !== undefined && value !== null) {
      if (!map.has(value)) {
        map.set(value, [])
      }
      map.get(value)?.push(item)
    }
  }

  return map
}

export const toMapByKeyArrayValue = <T extends object, K extends keyof T>(
  array: T[] = [],
  key: K,
): Map<T[K] extends (infer U)[] ? U : never, T[]> => {
  const map = new Map<T[K] extends (infer U)[] ? U : never, T[]>()

  for (const item of array) {
    const value = item[key]
    // Ensure value is an array
    if (Array.isArray(value)) {
      for (const subValue of value) {
        if (!map.has(subValue)) {
          map.set(subValue, [])
        }
        map.get(subValue)?.push(item)
      }
    }
  }

  return map
}

export const toMapByKeyFromObject = <T extends object>(object: Record<string, T[]>, key: keyof T): Record<string, Map<T[keyof T], T>> => {
  const map = {} as Record<string, Map<T[keyof T], T>>
  for (const [property, array] of Object.entries(object)) {
    map[property] = toMapByKey(array, key)
  }
  return map
}

// formatting:

export const formatAddress = (address: Address) => {
  return [
    [
      address?.street,
      address?.numberExterior,
      address?.numberInterior,
    ].filter(Boolean).join(', '),
    address?.neighborhood,
    [
      address?.postalCode ? `${address?.postalCode}` : '',
      address?.city,
      address?.district,
    ].filter(Boolean).join(', '),
    [
      address?.state,
      address?.country,
    ].filter(Boolean).join(', '),
  ].filter(Boolean).join(', ').toUpperCase().trim()
}

export const toUrl = (path: `/${string}`) => {
  return new URL(path, window.location.origin)
}
